<template lang="html">
  <router-view />
</template>

<script>
import { mapMutations } from "vuex";
import store from "@/store";

import { COMMUNITY_FETCH_ACTIVITY } from "@/store/community/activities.module";

export default {
  name: "ActivityView",

  async created() {
    await this.setCurrentActivity();
  },

  watch: {
    $route: "setCurrentActivity",
  },

  methods: {
    ...mapMutations({
      setForceUpdateDefaultActivity: "setForceUpdateDefaultActivity",
    }),
    async setCurrentActivity() {
      const activityId = this.$route.params.id;
      if (activityId) {
        await store.dispatch(COMMUNITY_FETCH_ACTIVITY, {
          activityId,
        });
      } else {
      }
    }

  },
};
</script>

<style scoped lang="scss">
</style>
